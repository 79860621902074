import React from 'react';
import classes from './ApprovalCell.module.css';
import Typography from "@material-ui/core/Typography";

const ApprovalCell = (props) => {
    console.log(props);
    let line1='';
    let line2='';
    let line3='';
    let detailLine='';
    /*line1 = props.approvalDetail.row.headerLine1 ? props.approvalDetail.row.headerLine1.substring(0,35) : '';
    line2 = props.approvalDetail.row.headerLine2 ? props.approvalDetail.row.headerLine2.substring(0,50) : '';
    line3 = props.approvalDetail.row.headerLine3 ? props.approvalDetail.row.headerLine3.substring(0, 135) : '';*/
    line1 = props.approvalDetail.row.headerLine1 ? props.approvalDetail.row.headerLine1 : '';
    line2 = props.approvalDetail.row.headerLine2 ? props.approvalDetail.row.headerLine2 : '';
    line3 = props.approvalDetail.row.headerLine3 ? props.approvalDetail.row.headerLine3 : '';
    
    detailLine=  line1 + ': ' + line2 + ': ' + line3;
    detailLine = detailLine.substring(0, 180);

    return (
        <div className={classes.ApprovalCell}>
            <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word', width:'285px'}}>
                <Typography variant="caption" display="block" style={{ wordWrap: "break-word" }}>{detailLine}</Typography></div>
        </div>
    )
}

export default ApprovalCell;
