import React from 'react';
import Pdficon from '../../resources/images/PDF-icon.png';

const PdfLink = (props) => {
    console.log(props);
    return (
        <div>
            <a href={props.approvalDetail.row.reportLink} target="_blank"><img height="36px" width="35px" src={Pdficon} /></a>
        </div>
    )
}

export default PdfLink;
