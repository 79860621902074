import * as actionTypes from './actionTypes'
import axios from 'axios';
import { ContactSupportOutlined } from '@material-ui/icons';

const AUTH = "ryewklfhdsakfhsfhslabvsmzxnvvbxvvcxzjklhfasdfru2347234904837hdasffasdvhfdsa";

export const applicationsGetSuccess = (data) =>{
    return {
        type: actionTypes.GET_APPLICATIONS_SUCCESS, 
        applications: data    
    };
}

export const headersGetSuccess = (data) =>{
    return {
        type: actionTypes.GET_HEADERS_SUCCESS, 
        headers: data    
    };
}

export const headersClear = ()=>{
    return {
        type: actionTypes.CLEAR_HEADERS 
    };
}

export const applicationsClear = () =>{
    return {
        type: actionTypes.CLEAR_APPLICATIONS
    };
}

export const applicationsGet = (userId) =>{
    return dispatch =>{
        axios.get('https://www.ibewextranet.org/iosAPI/Application/' + userId, {
            headers: {
              'auth': AUTH
            }
        })
        .then(response=>{
            dispatch(applicationsGetSuccess(response.data));
        });
    };
}

export const headersGet = (userId, applicationId) =>{
    return dispatch =>{
        axios.get('https://www.ibewextranet.org/iosAPI/Header/' + userId + '/' + applicationId, {
            headers: {
              'auth': AUTH
            }
        })
        .then(response=>{
            dispatch(headersGetSuccess(response.data));
        });
    };
}

export const headersApproveReject = (actionList) =>
{
    return dispatch =>{
        axios.post('https://www.ibewextranet.org/iosAPI/Header/', actionList, {
            headers: {
              'auth': AUTH
            }
        })
        .then(response=>{
            dispatch(headersApproveRejectSuccess(actionList));
        })
        .catch(err=>{
            console.log('err', err);
            console.log('err.response', err.response);
        });
    };
}


export const headersApproveRejectSuccess = (actionList) =>{
    console.log('in headersApproveRejectSuccess', actionList);

    return {
        type: actionTypes.APPROVE_REJECT_HEADERS_SUCCESS, 
        actionList: actionList
    }
}

export const userGet = (userName) =>{
    return dispatch =>{
        axios.get('https://www.ibewextranet.org/iosAPI/Login/' + userName, {
            headers: {
              'auth': AUTH
            }
        })
        .then(response=>{
            dispatch(userGetSuccess(response.data));
        })
        .catch(err => {
            if (err.response.status == 404){
                //no record for user_name
                dispatch(userGetSuccess(
                    //fake user with a bad password to trigger the error message in Login.js
                    [{
                       UserID: 9999,
                       Password: '999999999999999999',
                       FirstNamae: 'Error',
                       LastName: 'Error'
                    }]
                ))
            }
        });
    };
}

export const userGetSuccess = (user) =>{
    console.log(user);
    return {
        type: actionTypes. GET_USER_SUCCESS, 
        user: user
    }
}

export const userClear = () => {
    return {
        type: actionTypes. CLEAR_USER 
    }
}