import React, { Component } from 'react';
import Box from "@material-ui/core/Box";
import axios from 'axios';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions/actionTypes'
import * as actionCreators from '../../store/actions/actions'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/Textfield'
import CreateIcon from '@material-ui/icons/Create';
import ClearIcon from '@material-ui/icons/Clear';
import ViewListIcon from '@material-ui/icons/ViewList';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import classes from './Login.module.css';
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";


import './styles.css';
import { gridColumnsTotalWidthSelector } from '@material-ui/data-grid';

class Login extends Component {

    state = {
        dlgOpen: false, 
    }

    
    componentDidUpdate(){
        const user = this.props.user;
        if(user.length>0){
            //we have tried to login, set the cookie if the password matches
            //will need method to clear user and a way to return error if user
            //does not exist
            if (user[0].Password == document.getElementById('password').value){
                bake_cookie("userId", user[0].UserID, new Date('1/1/2030'));  //want to max expiry
                this.props.history.push({
                    pathname: '/applicationlist',
                });
            }
            else 
            {
                if(!this.state.dlgOpen){
                    this.setState({dlgOpen: true});
                }
            }  
        }        
    }

    handleLogin = () =>{
        const userName = document.getElementById('userName').value;
        this.props.onGetUser(userName);
    } 

    handleToClose = () =>{
        //clear the user from state
        this.props.onClearUser();
        this.setState({ dlgOpen: false })
    }

    

    render (){

        const loginButton = <div className={classes.buttonDiv}>
        <Button
        variant="contained"
        color="default"
        startIcon={<ArrowForwardIcon />}
        onClick={()=>{this.handleLogin()}}
        >Login</Button></div>;

        
        return <div>
          <p>Login</p>
          <Box
              display="flex"
              justifyContent="center"
              minHeight="100vh"
              >
              <div style={{ height: 345, width: 425, backgroundColor: 'white', color: '#282c34'}}>
                    <br /><br/>
                    <TextField id="userName" label="User_Name" variant="outlined" /><br /><br />
                    <TextField id="password" label="Password" variant="outlined" type='password' /><br /><br />
                    {loginButton}
              </div>
          </Box>
          <Dialog open={this.state.dlgOpen} onClose={()=>{this.handleToClose()}}>
                <DialogTitle>{"Login Error"}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    UserName or Password is incorrect.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{this.handleToClose()}}
                        color="primary" autoFocus>
                    Close
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    }
}

const mapStateToProps = (state) =>{
    return {
        user: state.user 
    }
};
  
const mapDispatchToProps = dispatch =>{
    return {
        onGetUser: (userName) => dispatch(actionCreators.userGet(userName)),
        onClearUser: () => dispatch(actionCreators.userClear())
    }
};

export default connect(mapStateToProps, mapDispatchToProps) (Login);;