import * as actionTypes from '../actions/actionTypes';

const initialState = {
    applications: [], 
    headers: [], 
    headersLoaded: false, 
    user: []
};

const reducer = (state= initialState, action) => {
    switch (action.type){
        case actionTypes.GET_APPLICATIONS_SUCCESS: {
            const applications = action.applications.map(app=>{
                return {id: app.ApplicationID, iosName: app.IOSName, approveCount: app.ApproveCount};
            })
            return {...state,
                applications: applications, 
                headers: state.headers, 
                headersLoaded: state.headersLoaded,
                user: state.user
            };
        }
        case actionTypes.GET_HEADERS_SUCCESS: {
            console.log('reducer getheaderssuccess, state', state);
            const headers = action.headers.map(header=>{
                return {id: header.refNbr,
                        appID: header.appID,
                        appName:header.appName,
                        approverID: header.approverID,
                        headerLine1: header.headerLine1,
                        headerLine2: header.headerLine2,
                        headerLine3: header.headerLine3,
                        moduleID: header.moduleID,
                        picture: header.picture,
                        reportLink: header.reportLink,
                        userID: header.userID
                };
            })
            return {...state,
                applications: state.applications,
                headers: headers, 
                headersLoaded: true, 
                user: state.user
            };
        }
        case actionTypes.CLEAR_APPLICATIONS: {
            return {...state,
                applications: [], 
                headers: state.headers, 
                headersLoaded: state.headersLoaded,
                user: state.user
            };
        }
        case actionTypes.CLEAR_HEADERS: {
            return {...state,
                applications: state.applications,
                headers: [],
                headersLoaded: false, 
                user: state.user
            };
        }
        case actionTypes.APPROVE_REJECT_HEADERS_SUCCESS: {
                const newHeaders = [...state.headers];
                const newApplications = [...state.applications];
                let actionIndex;
                let appIndex;
                action.actionList.map(actionDetail=>{
                     
                     actionIndex=newHeaders.findIndex(hdr=>hdr.id==actionDetail.refNbr);
                     if (actionIndex !== -1) {
                        newHeaders.splice(actionIndex, 1);
                     }
                     //this section may not be necessary as it is always
                     //reloaded from a db effect in ApplicationList.js
                     appIndex=newApplications.findIndex(app=>app.id==actionDetail.applicationID);
                     const newApp = newApplications[appIndex];
                     newApp.approveCount = newApp.approveCount - 1;
                     newApplications[appIndex] = newApp;
                     //this section may not be necessary end
                    });
                return {...state,
                    applications: newApplications,
                    headers: newHeaders, 
                    headersLoaded: state.headersLoaded,
                    user: state.user
                };
            }
        case actionTypes.GET_USER_SUCCESS :{
            const user = action.user;
            return {...state, 
                user: user
            }
        }
        case actionTypes.CLEAR_USER :{
            return {...state, 
                user: []
            }
        }
        default: {
           return state;
        }
    }

};

export default reducer;
