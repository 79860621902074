import React, { Component } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Box from "@material-ui/core/Box";
import axios from 'axios';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions/actionTypes'
import * as actionCreators from '../../store/actions/actions'
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import ClearIcon from '@material-ui/icons/Clear';
import ForwardIcon from '@material-ui/icons/Forward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import classes from './ApplicationList.module.css';
import './styles.css';
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';
import { WallpaperTwoTone } from '@material-ui/icons';


const columns = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'iosName', headerName: 'Application', flex: 1},
  { field: 'approveCount', headerName: 'Count', flex: 0.5}
];

class ApplicationList extends Component {

  state = {
    selectionModel: [], 
  }
    
  componentDidMount() {
    const userId = read_cookie("userId");
   
    if (userId.length==0){
        //if no cookie, send them to login
        this.props.history.push({
          pathname: '/login',
        });
    }
    else
    {
      //on get applictionsf from db
      this.props.onGetApplications(userId);
    }
  }

  componentWillUnmount(){
    //need to keep applications in state
    //this.props.onClearApplications();
 }

  handleCancelSelection = () =>{
    this.setState({selectionModel: []})
  }

  handleViewApprovals = () =>{
    const apps = [...this.props.applications];
    console.log(apps);
    const app = apps.find(app=>app.id==this.state.selectionModel);
    this.props.history.push({
        pathname: '/headerlist',
        state: { detail: app }
    });
  }

  handleGoToLogin = () =>{
    delete_cookie("userId")
    this.props.history.push({
      pathname: '/login'
    });
  }
    
  render (){

      let buttons = null;

      const loginButton = <div className={classes.buttonDiv}>
      <Button
      variant="contained"
      color="default"
      startIcon={<ArrowBackIcon />}
      onClick={()=>{this.handleGoToLogin()}}
      >Logout</Button></div>;
      
      const viewButton = <div className={classes.buttonDiv}>
      <Button
      variant="contained"
      color="default"
      startIcon={<ArrowForwardIcon />}
      onClick={()=>{this.handleViewApprovals()}}
      >View Approvals</Button></div>;

      
      const cancelButton = <div className={classes.buttonDiv}> 
      <Button
      variant="contained"
      color="default"
      startIcon={<ClearIcon />}
      onClick={()=>{this.handleCancelSelection()}}
      >Cancel Selection</Button></div>;

      if(this.state.selectionModel.length > 0){
        buttons = <div className={classes.buttonGroup}>
            {viewButton}
            {cancelButton}  
        </div>;
      }
      else
        {
          buttons=<div className={classes.buttonGroup}>{loginButton}</div>
        }

      let gridLoading = true;
      if (this.props.applications.length>0){
          gridLoading = false;
      }
     
      const selectionModel = this.state.selectionModel;

      return <div>
          <p>Approval Inbox</p>
          {buttons}
          <Box
              display="flex"
              justifyContent="center"
              minHeight="100vh"
              >
              <div style={{ height: 525, width: 425}}>
                  <DataGrid rows={this.props.applications} columns={columns} 
                  autoPageSize pagination selectionModel={selectionModel}
                  checkboxSelection
                  loading={gridLoading}
                  onSelectionModelChange={(e) => {
                      const oldState = new Set(this.state.selectionModel);
                      let newSelectionModel = e.selectionModel;
                      if (e.selectionModel.length==2){
                          newSelectionModel = e.selectionModel.filter(s=>!oldState.has(s));
                      }
                      if (e.selectionModel.length>2){
                          //to disable selectAll
                          newSelectionModel = []
                      }
                      this.setState({selectionModel: newSelectionModel});
                    }}        
                  />
              </div>
          </Box>
      </div>
  }
}

const mapStateToProps = (state) =>{
  return {
      applications: state.applications, 
  }
};

const mapDispatchToProps = dispatch =>{
  return {
      onGetApplications: (userId) => dispatch(actionCreators.applicationsGet(userId)), 
      onClearApplications: () => dispatch(actionCreators.applicationsClear())
  }
};


export default connect(mapStateToProps, mapDispatchToProps) (ApplicationList);

