import React, { Component } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Box from "@material-ui/core/Box";
import axios from 'axios';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions/actionTypes'
import * as actionCreators from '../../store/actions/actions'
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import ClearIcon from '@material-ui/icons/Clear';
import ViewListIcon from '@material-ui/icons/ViewList';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import classes from './HeaderList.module.css';
import ApprovalCell from '../../components/ApprovalCell/ApprovalCell';
import PdfLink from '../../components/PdfLink/PdfLink';
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";


import './styles.css';

const columns = [
    { field: 'id', headerName: 'ID', width: 100, hide: true }, 
    {field: 'details', flex: 1,
        headerName: 'Details',
        sortable: false,
        renderCell: (params) => {
            return <div><ApprovalCell approvalDetail={params} /></div>
        }
    },  
    {field: 'Pdf', flex: 0.25,
        headerName: '',
        sortable: false,
        renderCell: (params) => {
            return <div><PdfLink approvalDetail={params} /></div>
        }
    }
];
  

class HeaderList extends Component {
    state = {
        selectionModel: [], 
        dlgApproveOpen: false,
        dlgRejectOpen: false 
    }

    
    componentDidMount() {
       const userId = read_cookie("userId");
       //get apps just to be safe (ie. in case was reloaded page)
       this.props.onGetApplications(userId);
       //props.location is the state that was pushed from the application list
       this.props.onGetHeaders(userId, this.props.location.state.detail.id);
    }

    componentWillUnmount(){
       this.props.onClearHeaders();
    }

    handleCancelSelection = () =>{
        this.setState({selectionModel: []})
    }

    handleApproveDlgOpen = () =>{
        this.setState({dlgApproveOpen: true})
    }

    handleApproveDlgClose = () =>{
        this.setState({dlgApproveOpen: false})
    }
    
    handleApproveSelected = () =>{
        const actionList = [];
        const headers = [...this.props.headers];
        let header;
        this.state.selectionModel.map(selection=>{
            header = headers.find(hdr=>hdr.id==selection);
            //actionType 1 = approve
            actionList.push({userID: header.userID, applicationID: header.appID, moduleID: header.moduleID, refNbr: header.id, approverID: header.approverID, actionType: 1});
        });
        this.props.onApproveReject(actionList);
        this.handleApproveDlgClose();
    }

    handleRejectDlgOpen = () =>{
        this.setState({dlgRejectOpen: true})
    }

    handleRejectDlgClose = () =>{
        this.setState({dlgRejectOpen: false})
    }

    handleRejectSelected = () =>{
        const actionList = [];
        const headers = [...this.props.headers];
        let header;
        this.state.selectionModel.map(selection=>{
            header = headers.find(hdr=>hdr.id==selection);
            //actionType 2 = reject
            actionList.push({userID: header.userID, applicationID: header.appID, moduleID: header.moduleID, refNbr: header.id, approverID: header.approverID, actionType: 2});
        });
        this.props.onApproveReject(actionList);
        this.handleRejectDlgClose();
    }

    
    handleBackToInbox = ()=>{
        //go back to inbox
        this.props.history.goBack();
    }

   
    render (){

        const iosName = this.props.location.state.detail.iosName;
        const appId = this.props.location.state.detail.id;
        const selectionModel = this.state.selectionModel;
        
        let buttons = null;

        const inboxButton = <div className={classes.buttonDiv}>
        <Button
        variant="contained"
        color="default"
        startIcon={<ArrowBackIcon />}
        onClick={()=>{this.handleBackToInbox()}}
        >Inbox</Button></div>;
  
      
        const approveButton = <div className={classes.buttonDiv}>
        <Button
        variant="contained"
        color="default"
        startIcon={<CheckCircle />}
        onClick={()=>{this.handleApproveDlgOpen()}}
        >Approve</Button></div>;

        const rejectButton = <div className={classes.buttonDiv}>
        <Button
        variant="contained"
        color="default"
        startIcon={<ThumbDownAltIcon />}
        onClick={()=>{this.handleRejectDlgOpen()}}
        >Reject</Button></div>;
  
        const cancelButton = <div className={classes.buttonDiv}> 
        <Button
        variant="contained"
        color="default"
        startIcon={<ClearIcon />}
        onClick={()=>{this.handleCancelSelection()}}
        >Cancel</Button></div>;
  
        if(this.state.selectionModel.length > 0){
            buttons = <div className={classes.buttonGroup}>
                {inboxButton}
                {approveButton}
                {rejectButton}
                {cancelButton}  
            </div>;
        }
        else{
            buttons = <div className={classes.buttonGroup}>
                {inboxButton}
            </div>;
        }

        return <div>
            <p>{iosName} Approval List</p>
            {buttons}
            <Box
              display="flex"
              justifyContent="center"
              minHeight="100vh"
              >
              <div style={{ height: 525, width: 425}}>
                    <DataGrid rows={this.props.headers} columns={columns} 
                        autoPageSize pagination selectionModel={selectionModel}
                        checkboxSelection
                        loading={!this.props.headersLoaded}
                        rowHeight={85}
                        disableSelectionOnClick
                        onSelectionModelChange={(e) => {
                            this.setState({selectionModel: e.selectionModel});
                    }}        
                />
              </div>
            </Box>
            <Dialog open={this.state.dlgApproveOpen} onClose={()=>{this.handleApproveDlgClose()}}>
                <DialogTitle>{"Approve Items Confirmation"}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    This action will Approve the selections.  Do you wish to continue?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{this.handleApproveSelected()}}
                        color="primary" autoFocus>
                    Yes
                </Button>
                <Button onClick={()=>{this.handleApproveDlgClose()}}
                        color="warn" autoFocus>
                    No
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.dlgRejectOpen} onClose={()=>{this.handleRejectDlgClose()}}>
                <DialogTitle>{"**REJECT** Items Confirmation"}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    This action will **REJECT** the selections.  Do you wish to continue?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{this.handleRejectSelected()}}
                        color="primary" autoFocus>
                    Yes
                </Button>
                <Button onClick={()=>{this.handleRejectDlgClose()}}
                        color="warn" autoFocus>
                    No
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    }

}

const mapStateToProps = (state) =>{
    return {
        headers: state.headers, 
        headersLoaded: state.headersLoaded
    }
};
  
const mapDispatchToProps = dispatch =>{
    return {
        onGetHeaders: (userId, applicationId) => dispatch(actionCreators.headersGet(userId, applicationId)),
        onClearHeaders: () => dispatch(actionCreators.headersClear()), 
        onApproveReject: (approveList) => dispatch(actionCreators.headersApproveReject(approveList)), 
        onGetApplications: (userId) => dispatch(actionCreators.applicationsGet(userId)) 
    }
};
  
  
export default connect(mapStateToProps, mapDispatchToProps) (HeaderList);

  
