export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_HEADERS_SUCCESS = 'GET_HEADERS_SUCCESS';
export const CLEAR_HEADERS = 'CLEAR_HEADERS';
export const CLEAR_APPLICATIONS = 'CLEAR_APPLICATIONS';
export const APPROVE_REJECT_HEADERS = 'APPROVE_REJECT_HEADERS';  //actiontype will be 1 for approve, 2 for reject
export const APPROVE_REJECT_HEADERS_SUCCESS = 'APPROVE_REJECT_HEADERS_SUCCESS'; //actiontype will be 1 for approve, 2 for reject
export const GET_USER = 'GET_USER'; 
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'; 
export const CLEAR_USER = 'CLEAR_USER';






