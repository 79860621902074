import './App.css';
import ApplicationList from './containers/ApplicationList/ApplicationList';
import HeaderList from './containers/HeaderList/HeaderList';
import Login from './containers/Login/Login';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import reducer from './store/reducers/reducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';



function App() {

  
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__ || compose;
  
  //const store = createStore(reducer, applyMiddleware(thunk));
  
  const store = createStore(reducer, composeWithDevTools(
      applyMiddleware(thunk)));

  return (
    <Provider store={store}>
        <div className="App">
            <BrowserRouter>
              <Switch>
                <Route path="/" exact component={ApplicationList} />
                <Route path="/login" component={Login}/>
                <Route path="/applicationlist" component={ApplicationList}/>
                <Route path="/headerlist" component={HeaderList}/>
              </Switch>
            </BrowserRouter>
        </div>
    </Provider>
  );
}

export default App;


